import React, {useState} from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Helmet from "react-helmet"
import { PageTitle } from "../components/UtilComponents"
import Masonry from "react-masonry-css"
// @ts-ignore
import { Bars } from "react-loader-spinner"
import Fade from "react-reveal/Fade"
import GatsbyImage, { FluidObject } from "gatsby-image"
import SEO from "../components/seo"

type ProjectGalleryImage = {
  position: number;
  galleryImage : { publicURL: string | undefined;
    childImageSharp: { fluid: FluidObject | FluidObject[] }}
}

const VideoWrapper = styled(({videoID, className}) => {
  const [isLoaded, setIsLoaded] = useState(true)
  const handleReady = () => {
    setIsLoaded(false)
  }
  return <div className={className}>
    { isLoaded ? <div>
      <Bars
        height="80"
        width="80"
        color="black"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div> : null }  <div className={`video ${!isLoaded ? 'open' : null}`}>
      <div style={{ padding: "56,25% 0 0 0", position: "relative", width:"100%" }}>
        <iframe onLoad={handleReady}
                src={`https://player.vimeo.com/video/${videoID}?h=3311e26714&title=0&byline=0&portrait=0`}
                style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }} frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
      </div>
      <script defer src="https://player.vimeo.com/api/player.js"></script>
    </div>
  </div>
})`
  display: flex;
  justify-content: center;
  align-items: center;
  height:600px;
 .video {
   display: none;
   max-width:1200px;
   width:100%;
   height:600px;
   margin:auto;
   &.open {
     display:flex;
   }
 }
`

// @ts-ignore
const ProjectItemPage = ({ className, data,location:{pathname:path}, pageContext }) => {
  const breakpointColumnsObj = {
    default: 5,
    1100: 3,
    992: 2,
    700: 1
  }

  const project = data.portfolio
  const videoID = project.additionalData.find(ad => ad.key === "VideoID").locales.find(l => l.iso === pageContext.lang).value
  const localText = project.locales.find((l: { iso: string }) => l.iso === pageContext.lang)
  return (
    <Layout lang={pageContext.lang} page={path}>
      <SEO title={localText.metaTitle}
           description={localText.metaDescription}
           keywords={localText.metaKeywords}
      />
      <Helmet>
        <link rel="stylesheet"
              href="https://cdn.jsdelivr.net/gh/fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.css" />
      </Helmet>
      <div className={className}>
        <div className="content content--design content--inner">
          <PageTitle>
            {localText.title}
          </PageTitle>
          <h3 className="subtitle" dangerouslySetInnerHTML={{ __html: localText.content }} />
          <h3 className="subtitle">{localText.description}</h3>
          {project.authorUrl && project.authorUrl !== "#" &&
            <div className="portfolio-url">Visit the website <a target="blank"
                                                                href={project.authorUrl}>{project.authorUrl.slice(11, -1)}</a> for
              more information</div>}
          {videoID ? <VideoWrapper videoID={videoID} /> : null}
          <section className="section project-single-page">
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {project.gallery.galleryImages.map((projectImage: ProjectGalleryImage, index: React.Key | null | undefined) => {
                return <Fade key={projectImage.galleryImage.uid + index} bottom
                             delay={index === 0 || index === undefined ? 0 : index * 50}><a style={{ display: "block" }}
                                                                                            data-fancybox="gallery"
                                                                                            href={projectImage.galleryImage.publicURL}><GatsbyImage
                  key={index} fluid={projectImage.galleryImage.childImageSharp.fluid} /></a></Fade>
              })}
            </Masonry>
          </section>
        </div>
      </div>
      <Helmet>
        <script
          src="https://code.jquery.com/jquery-3.6.0.min.js"
          integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4="
          crossOrigin="anonymous" />
        <script src="https://cdn.jsdelivr.net/gh/fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js" />
      </Helmet>
    </Layout>
  )
}

export const query = graphql`
query PortfolioPageItem($uid: String!) {
    portfolio(uid: {eq: $uid}) {
    authorUrl
    additionalData {
        key
        locales {
            iso
            value
        }
    }    
    locales {
      title
      description
      content
      iso
      metaDescription
      metaKeywords
      metaTitle
    }
    gallery {
      galleryImages {
        position
        galleryImage {
          publicURL
          uid
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      locales {
        iso
        title
      }
    }
    }
  }
`

export default ProjectItemPage
